import React, { useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import AvatarEditor from 'react-avatar-editor';
import Slider from '@mui/material/Slider';
import { Grid, Paper, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Alert from '@mui/material/Alert';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormControl from '@mui/material/FormControl';
import TextAreaWithLimit from './TextAreaWithLimit';
import PubSub from 'pubsub-js';

const Input = styled('input')({
   display: 'none',
});

const PhotoUploader = forwardRef((props, ref) => {
   const maxPhotoSizeMb = 20;

   let editorRefs = [];
   for(let i = 0; i < (props.multiple || 1); i++) {
      editorRefs[i] = null;
   }

   const [fullSizeBase64Photos, realSetFullSizeBase64Photos] = useState([]);
   const [selectedPhotos, setSelectedPhotos] = useState([]);

   const handlePhotoScaleChange = (event, newValue, index) => {
      let _selectedPhotos = [...selectedPhotos];
      _selectedPhotos[index] = {...selectedPhotos[index], scale: newValue};
      setSelectedPhotos(_selectedPhotos);
   };

   const handlePhotoCaptionChange = (newValue, index) => {
      let _selectedPhotos = [...selectedPhotos];
      _selectedPhotos[index] = {...selectedPhotos[index], caption: newValue};
      setSelectedPhotos(_selectedPhotos);
   };

   const handlePhotoShowInMausoleumChange = (newValue, index) => {
      let _selectedPhotos = [...selectedPhotos];
      _selectedPhotos[index] = {...selectedPhotos[index], showInMausoleum: newValue};
      setSelectedPhotos(_selectedPhotos);
   };

   const setFullSizeBase64Photos = (fullSizeBase64, index) => {
      fullSizeBase64Photos[index] = fullSizeBase64;
   };

   const setEditorRefByIndex = (editor, index) => {
      editorRefs[index] = editor;
   };

   const [photoValidation, setPhotoValidation] = useState([]);
   const validatePhotos = () => {
      const _photosValid = selectedPhotos.map((photo) => ({
         fileSizeValid: !(photo.file.size > maxPhotoSizeMb * 1024 * 1024),
         captionValid: !(photo.caption && photo.caption.length < 2),
      }));

      setPhotoValidation(_photosValid);

      for(let i = 0; i < _photosValid.length; i++) {
         if(!_photosValid[i].fileSizeValid || !_photosValid[i].captionValid)
            return false;
      }

      return true;
   };

   // https://www.npmjs.com/package/react-avatar-editor
   const getPhotos = () => {
      return editorRefs.filter((editor) => (editor ? true : false)).map((editorRef, index) => {
         if (editorRef) {
            // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
            // drawn on another canvas, or added to the DOM.
            // const canvas = editorRef.getImage();

            // If you want the image resized to the canvas size (also a HTMLCanvasElement)
            const canvasScaled = editorRef.getImageScaledToCanvas();

            const photo = {
               fileName: selectedPhotos[index].file.name,
               size: selectedPhotos[index].file.size, //int in bytes

               // this version converts jpg to png and seems to drastically increase file size
               // base64PhotoFullSize: canvas.toDataURL('data:image/png'/*, encoderOptions*/),

               base64PhotoFullSize: fullSizeBase64Photos[index],
               // base64PhotoScaled: canvasScaled.toDataURL('data:image/png'/*, encoderOptions*/),

               base64PhotoScaled: canvasScaled.toDataURL('image/jpeg', 0.95),
               x: editorRef.state.image.x,
               y: editorRef.state.image.y,
               mx: editorRef.state.mx || 0,
               my: editorRef.state.my || 0,
               scale: editorRef.props.scale,
               rotate: editorRef.props.rotate,
               width: editorRef.state.image.resource.width,
               height: editorRef.state.image.resource.height,
            };

            if(props.enableCaptions)
               photo.caption = selectedPhotos[index].caption;

            if(props.enableMausoleumSwitch)
               photo.showInMausoleum = selectedPhotos[index].showInMausoleum;

            return photo;
         }

         return null;
      });
   }

   const handlePhotosSelected = (event) => {
      let _selectedPhotos = [];
      for(let i = 0; i < Math.min(event.target.files.length, (props.multiple || 1)); i++) {

         const reader = new FileReader();
         reader.onload = () => {
            setFullSizeBase64Photos(reader.result, i)
         };
         console.log(event.target.files[i]);
         if(event.target.files[i] instanceof File && event.target.files[i].type !== 'image/avif') {
            _selectedPhotos.push({
               scale: 1,
               caption: '',
               showInMausoleum: false,
               file: event.target.files[i]
            });

            reader.readAsDataURL(event.target.files[i]);
         }
         if(event.target.files[i].type === 'image/avif') {
            PubSub.publish('notification.show-snackbar', {
               open: true,
               autoHideDuration: 5000,
               severity: 'error',
               message: `We do not currently support the AVIF file format.`
            });
         }
      }

      setSelectedPhotos(_selectedPhotos);
   };

   // control state from parent component
   useImperativeHandle(ref, () => ({
      getPhotos() {
         return getPhotos();
      },

      getPhoto() {
         const photos = getPhotos();
         return photos.length ? photos[0] : null;
      },

      validate() {
         return validatePhotos();
      },

      reset() {
         setSelectedPhotos([]);
         realSetFullSizeBase64Photos([]);
         document.getElementById('photo-uploader-input').value = null;
      },
   }));

   return (<Stack spacing={2}>
      <Box>
         <label htmlFor="photo-uploader-input">
            <Input
               accept="image/*"
               // accept="image/x-png,image/gif,image/jpeg"
               id="photo-uploader-input"
               name="photo-uploader-input"
               multiple={props.multiple}
               type="file"
               onChange={handlePhotosSelected}
            />
            <Button
               variant="outlined"
               component="span"
               startIcon={<CloudUploadIcon />}
            >
               {props.buttonText || 'Choose File'}
            </Button>
         </label>
      </Box>
      <Box>
         {selectedPhotos.map((photo, index) => (<Paper key={index} sx={{mb: 1, p: 1}} variant="outlined">
            <Grid container>
               <Grid item xs={12} sx={{backgroundColor: '#000000', height: props.height + 100}}>
                  <AvatarEditor
                     ref={(editor) => setEditorRefByIndex(editor, index)}
                     image={photo.file}
                     width={props.width}
                     height={props.height}
                     border={50}
                     color={[255, 255, 255, 0.6]}
                     scale={photo.scale}
                     rotate={0}
                  />
               </Grid>

               <Grid item xs={12}>
                  <Slider
                     aria-label="Photo Scale"
                     step={0.1}
                     min={0}
                     max={2}
                     value={photo.scale}
                     onChange={(event, newValue) => handlePhotoScaleChange(event, newValue, index)}
                  />
               </Grid>

               {props.enableMausoleumSwitch ? <Grid item xs={12}>
                  <FormGroup>
                     <FormControlLabel control={<Switch
                        id={`show-on-mausoleum-switch-${index}`}
                        checked={photo.showInMausoleum}
                        onChange={(event) => handlePhotoShowInMausoleumChange(event.target.checked, index)}
                        inputProps={{ 'aria-label': 'show-on-mausoleum-switch' }}
                     />} label="Show on Mausoleum" />
                  </FormGroup>
               </Grid> : null}

               {props.enableCaptions ? <Grid item xs={12}>
                  <FormControl fullWidth>
                     <TextAreaWithLimit
                        id={`caption-input-${index}`}
                        label="Caption"
                        maxLength={255}
                        value={photo.caption}
                        onChange={(event) => handlePhotoCaptionChange(event.target.value, index)}
                        error={photoValidation.length && !photoValidation[index].captionValid ? true : false}
                        helperText={photoValidation.length && !photoValidation[index].captionValid ? 'Caption must be at least two characters if provided' : null}
                        placeholder="Add a caption..."
                        rows={3}
                     />
                  </FormControl>
               </Grid> : null}

               {(photoValidation.length && !photoValidation[index].fileSizeValid) ? <Grid item xs={12}><Alert severity="error">{`Photo file size must be less than ${maxPhotoSizeMb} MB.`}</Alert></Grid> : null }
            </Grid>
         </Paper>))}
      </Box>
   </Stack>)
});

PhotoUploader.propTypes = {
   width: PropTypes.number.isRequired,
   height: PropTypes.number.isRequired,
   buttonText: PropTypes.string,
   multiple: PropTypes.number,
   enableCaptions: PropTypes.bool,
   enableMausoleumSwitch: PropTypes.bool,
};

export default PhotoUploader;
